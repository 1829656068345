import Vue from 'vue';
import MainLayout from '@/layouts/MainLayout';
import { forgotPassword } from '@/services/users';
import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'ForgotPassword',

  components: {
    MainLayout,
  },

  data() {
    return {
      email: null,
    };
  },

  validations: {
    email: {
      required: required,
    },
  },

  methods: {
    async onClickForgotPassword() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const response = await forgotPassword(this.email);

      if (!response) {
        return;
      }

      this.notifySuccess();
    },
  },
});
